<template>
  <div>
    <a-card title="调拨单详情">
       <!-- <a-button slot="extra" type="primary" style="margin-right: 8px;" ghost v-print="'#printContent'"> <a-icon type="printer" />打印</a-button> -->
      <a-button slot="extra" type="primary" ghost @click="() => { this.$router.go(-1); }"> <a-icon type="left" />返回</a-button>
      <section id="printContent">
        <a-spin :spinning="orderLoading">
          <img class="jsbarcode" id="jsbarcode" ref="jsbarcode"/>
          <a-descriptions bordered>
            <a-descriptions-item label="调拨单号">
              {{ stockTransferOrderItem.number }}
            </a-descriptions-item>
            <a-descriptions-item label="调出仓库">
              {{ stockTransferOrderItem.out_warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item label="调出库区">
              {{ stockTransferOrderItem.out_area_name }}
            </a-descriptions-item>
            <a-descriptions-item label="调入仓库">
              {{ stockTransferOrderItem.in_warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item label="调入库区">
              {{ stockTransferOrderItem.in_area_name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ stockTransferOrderItem.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="调拨总数量">
              {{ stockTransferOrderItem.total_quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ stockTransferOrderItem.remark }}
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>
        <div style="margin-top: 16px;">
          <a-table rowKey="id" size="small" :columns="columns" :data-source="stockTransferMaterialItems"
            :loading="materialLoading" :pagination="false">
          </a-table>
        </div>
      </section>
    </a-card>
  </div>
</template>

<script>
  import JsBarcode from 'jsbarcode'
  import { stockTransferOrderRetrieve, stockTransferOrderMaterials } from '@/api/stockTransfer';
  import { columns } from './columns';

  export default {
    data() {
      return {
        columns,
        orderLoading: false,
        materialLoading: false,
        stockTransferOrder: undefined,
        stockTransferOrderItem: {},
        stockTransferMaterialItems: [],
      }
    },
    methods: {
      initData() {
        this.stockTransferOrder = this.$route.query.id;
        this.orderLoading = true;
        document.getElementById('jsbarcode').html = ''
        stockTransferOrderRetrieve({ id: this.stockTransferOrder }).then(data => {
          this.stockTransferOrderItem = data;
          var jsbarcode = new JsBarcode(this.$refs.jsbarcode, data.number, {
            format: 'CODE128',
            width: 2,
            height: 35,
            displayValue:true,
            background: '#ffffff',
            lineColor: '#000000'
          })
        }).finally(() => {
          this.orderLoading = false;
        });

        this.materialLoading = true;
        stockTransferOrderMaterials({ id: this.stockTransferOrder }).then(data => {
          this.stockTransferMaterialItems = data;
        }).finally(() => {
          this.materialLoading = false;
        });
      },
    },
    mounted() {
      this.initData();
    },
  }
</script>
<style>
  .jsbarcode {
    display: inline-block;
    float: right;
  }
</style>