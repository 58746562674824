export const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return index + 1
    },
  },
  // {
  //   title: '调出库位',
  //   dataIndex: 'out_location_number',
  //   key: 'out_location_number',
  // },
  // {
  //   title: '调入库位',
  //   dataIndex: 'in_location_number',
  //   key: 'in_location_number',
  // },
  {
    title: '名称',
    dataIndex: 'material_name',
    key: 'material_name',
    width: 300
  },
  {
    title: '产品英文名称',
    dataIndex: 'material_english_name',
    key: 'material_english_name',
    width: 300
  },
  {
    title: '产品编号',
    dataIndex: 'material_number',
    key: 'material_number',
  },
  {
    title: '调拨数量',
    dataIndex: 'total_quantity',
    key: 'total_quantity',
  },
  {
    title: '单位',
    dataIndex: 'unit',
    key: 'unit',
  },
]